* {
  box-sizing: border-box;
}

body {
  padding: 0;
  text-align: center;
  background-image: url(/bg.jpg);
  font-family: 'Playfair Display', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 20px;
  line-height: 30px;
}

.app {
  text-align: center;
  max-width: 750px;
  margin: 0 auto;
}

.home {
  border: 5px solid #333;
  margin-bottom: 30px;
}

.meta {
  font-size: 12px;
  color: #444;
}

.nav {
  list-style-type: none;
  padding: 0;
  margin-bottom: 40px;
}

.nav li {
  display: inline-block;
  line-height: 30px;
  padding: 0 4px;
}

@media (min-width: 900px) {
  .app {
    max-width: initial;
  }

  header {
    width: 20%;
    position: fixed;
  }

  main {
    width: 70%;
    float: left;
    margin-left: 28%;
  }

  .nav li {
    display: block;
  }
}

section {
  animation-name: bodytextEntry;
  animation-duration: 1s;
}

section img {
  border: 15px solid #FFF;
  box-shadow: 0 0 10px 10px #DDD;
}

.scroll-btn {
  position: fixed;
  z-index: 1;
  bottom: 50px;
  right: 50px;
  display: block;
  background: url(/scroll.png) no-repeat;
  width: 37px;
  height: 26px;
  outline: none;
  border: none;
  cursor: pointer;
}

@keyframes bodytextEntry {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
