* {
  box-sizing: border-box;
}

body {
  padding: 0;
  text-align: center;
  background-image: url(/bg.jpg);
  font-family: 'Playfair Display', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 20px;
  line-height: 30px;
}

.app {
  text-align: center;
  max-width: 750px;
  margin: 0 auto;
}

.home {
  border: 5px solid #333;
  margin-bottom: 30px;
}

.meta {
  font-size: 12px;
  color: #444;
}

.nav {
  list-style-type: none;
  padding: 0;
  margin-bottom: 40px;
}

.nav li {
  display: inline-block;
  line-height: 30px;
  padding: 0 4px;
}

@media (min-width: 900px) {
  .app {
    max-width: none;
    max-width: initial;
  }

  header {
    width: 20%;
    position: fixed;
  }

  main {
    width: 70%;
    float: left;
    margin-left: 28%;
  }

  .nav li {
    display: block;
  }
}

section {
  -webkit-animation-name: bodytextEntry;
          animation-name: bodytextEntry;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

section img {
  border: 15px solid #FFF;
  box-shadow: 0 0 10px 10px #DDD;
}

.scroll-btn {
  position: fixed;
  z-index: 1;
  bottom: 50px;
  right: 50px;
  display: block;
  background: url(/scroll.png) no-repeat;
  width: 37px;
  height: 26px;
  outline: none;
  border: none;
  cursor: pointer;
}

@-webkit-keyframes bodytextEntry {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bodytextEntry {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.curriculum {
  text-align: left;
}

.curriculum li {
  line-height: 20px;
}

.curriculum .photos {
  margin-top: 50px;
  text-align: center;
}

.curriculum .photos img { margin-bottom: 10px; }
.curriculum .photos div { margin-bottom: 50px; }

blockquote {
  font-size: 1.4em;
  width: 60%;
  margin: 50px auto;
  font-style: italic;
  color: #555555;
  padding: 1.2em 30px 1.2em 75px;
  border-left: 8px solid #78C0A8 ;
  line-height:1.6;
  position: relative;
  background: rgba(230, 230, 230, 0.3);
}

blockquote::before {
  font-family: Arial;
  content: "\201C";
  color: #78C0A8;
  font-size: 4em;
  position: absolute;
  left: 10px;
  top:-10px;
}

blockquote::after{
  content: '';
}

blockquote span {
  display: block;
  color: #333333;
  font-style: normal;
  font-weight: bold;
  margin-top: 1em;
}

.works img {
  width: 100%;
  margin: 10px 0 40px 0;
}

@media (max-width: 600px) {
  .works img {
    border-width: 5px;
  }
}

.exhibits img { margin-bottom: 10px; max-width: 100%; }
.exhibits div { margin-bottom: 50px; }
