.curriculum {
  text-align: left;
}

.curriculum li {
  line-height: 20px;
}

.curriculum .photos {
  margin-top: 50px;
  text-align: center;
}

.curriculum .photos img { margin-bottom: 10px; }
.curriculum .photos div { margin-bottom: 50px; }

blockquote {
  font-size: 1.4em;
  width: 60%;
  margin: 50px auto;
  font-style: italic;
  color: #555555;
  padding: 1.2em 30px 1.2em 75px;
  border-left: 8px solid #78C0A8 ;
  line-height:1.6;
  position: relative;
  background: rgba(230, 230, 230, 0.3);
}

blockquote::before {
  font-family: Arial;
  content: "\201C";
  color: #78C0A8;
  font-size: 4em;
  position: absolute;
  left: 10px;
  top:-10px;
}

blockquote::after{
  content: '';
}

blockquote span {
  display: block;
  color: #333333;
  font-style: normal;
  font-weight: bold;
  margin-top: 1em;
}
